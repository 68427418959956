import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | wSAC DeFi',
  defaultTitle: 'wSAC DeFi',
  description:
    'wSAC swap platform powered by PancakeSwap.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@PancakeSwap',
    site: '@PancakeSwap',
  },
  openGraph: {
    title: 'wSAC DeFi on BNB Smart Chain (BSC)',
    description:
      'wSAC swap platform powered by PancakeSwap.',
    images: [{ url: 'https://dex.sanuscoin.com/images/wSAC-icon.png' }],
  },
}
