import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import { Box, Flex } from "../Box";
import { Link } from "../Link";
import {
  StyledFooter,
  StyledIconMobileContainer,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledText,
  StyledToolsContainer,
} from "./styles";

import { vars } from "../../css/vars.css";
import { Button } from "../Button";
import CakePrice from "../CakePrice/CakePrice";
import LangSelector from "../LangSelector/LangSelector";
import { ArrowForwardIcon, LogoWithTextIcon } from "../Svg";
import { ThemeSwitcher } from "../ThemeSwitcher";
import { FooterProps } from "./types";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const isMounted = useIsMounted();
  return (
    <StyledFooter
      data-theme="dark"
      p={["40px 16px", null, "56px 40px 32px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
    >
      <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
        <StyledIconMobileContainer display={["block", null, "none"]}>
            <Link href="https://sanuslife.com" target="_blank">
              <img src="/images/SANUSLIFE-Logo-Horizontal.svg" alt="phishing-warning" width="130px" />
            </Link>
            <div style={{color: 'white'}}>
              <ul style={{listStyle: 'none', display: 'inline-flex', paddingTop: '20px'}}>
                <li style={{paddingRight: '50px'}}>SANUS<strong>WALLET</strong>
                  <Link href="https://play.google.com/store/apps/details?id=com.sanuslife.sanuswallet2&amp;hl=gsw&amp;gl=US" target="_blank">
                    <img src="/images/google-play-badge.png" alt="" width="130px" style={{paddingTop: '10px'}} />
                  </Link>
                  <Link href="https://wallet2.sanuscoin.com/" target="_blank">
                    <img src="/images/web-badge-01.png" alt="" width="120px" style={{paddingTop: '10px'}} />
                  </Link>
                </li>
                <li>SANUS<strong>APP</strong>
                  <Link href="https://play.google.com/store/apps/details?id=sanuslife.sanusapp&amp;gl=IT" target="_blank">
                    <img src="/images/google-play-badge.png" alt="" width="130px" style={{paddingTop: '10px'}} />
                  </Link>
                  <Link href="https://apps.apple.com/de/app/sanusapp/id1594665228" target="_blank">
                    <img src="/images/Appstore2x.png" alt="" width="120px" style={{paddingTop: '10px'}} />
                  </Link>
                </li>
              </ul>
            </div>
        </StyledIconMobileContainer>
        <Flex
          order={[2, null, 1]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
          alignItems="flex-start"
          mb={["42px", null, "36px"]}
        >
          {items?.map((item) => (
            <StyledList key={item.label}>
              <StyledListItem>{item.label}</StyledListItem>
              {item.items?.map(({ label, href, isHighlighted = false }) => (
                <StyledListItem key={label}>
                  {href ? (
                    <Link
                      data-theme="dark"
                      href={href}
                      target="_blank"
                      rel="noreferrer noopener"
                      color={isHighlighted ? vars.colors.warning : "text"}
                      bold={false}
                    >
                      {label}
                    </Link>
                  ) : (
                    <StyledText>{label}</StyledText>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          ))}
          <Box display={["none", null, "block"]}>
            <Link href="https://sanuslife.com" target="_blank">
              <img src="/images/SANUSLIFE-Logo-Horizontal.svg" alt="phishing-warning" width="160px" />
            </Link>
            <div style={{color: 'white'}}>
              <ul style={{listStyle: 'none', display: 'inline-flex', paddingTop: '20px'}}>
                <li style={{paddingRight: '50px'}}>SANUS<strong>WALLET</strong>
                  <Link href="https://play.google.com/store/apps/details?id=com.sanuslife.sanuswallet2&amp;hl=gsw&amp;gl=US" target="_blank">
                    <img src="/images/google-play-badge.png" alt="" width="130px" style={{paddingTop: '10px'}} />
                  </Link>
                  <Link href="https://wallet2.sanuscoin.com/" target="_blank">
                    <img src="/images/web-badge-01.png" alt="" width="120px" style={{paddingTop: '10px'}} />
                  </Link>
                </li>
                <li>SANUS<strong>APP</strong>
                  <Link href="https://play.google.com/store/apps/details?id=sanuslife.sanusapp&amp;gl=IT" target="_blank">
                    <img src="/images/google-play-badge.png" alt="" width="130px" style={{paddingTop: '10px'}} />
                  </Link>
                  <Link href="https://apps.apple.com/de/app/sanusapp/id1594665228" target="_blank">
                    <img src="/images/Appstore2x.png" alt="" width="120px" style={{paddingTop: '10px'}} />
                  </Link>
                </li>
              </ul>
            </div>
          </Box>
        </Flex>
        <StyledSocialLinks order={[2]} pb={["42px", null, "32px"]} mb={["0", null, "32px"]} />
        <StyledToolsContainer
          data-theme="dark"
          order={[1, null, 3]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
        >
          <Flex order={[2, null, 1]} alignItems="center">
            {isMounted && <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />}
            <LangSelector
              currentLang={currentLang}
              langs={langs}
              setLang={setLang}
              color="textSubtle"
              dropdownPosition="top-right"
            />
          </Flex>
          <span style={{color: 'white'}}>©SANUSLIFE 2023 - <i>powered by PancakeSwap</i></span>
          {/* <Flex order={[1, null, 2]} mb={["24px", null, "0"]} justifyContent="space-between" alignItems="center">
            <Box mr="20px">
              <CakePrice chainId={chainId} cakePriceUsd={cakePriceUsd} color="textSubtle" />
            </Box>
            <Button
              data-theme={isDark ? "dark" : "light"}
              as="a"
              href={buyCakeLink}
              target="_blank"
              scale="sm"
              endIcon={<ArrowForwardIcon color="backgroundAlt" />}
            >
              {buyCakeLabel}
            </Button>
          </Flex> */}
        </StyledToolsContainer>
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
